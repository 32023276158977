import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/nonbmaWave"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb_new from "src/components/btf_cb_new"
import FfDisclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const wavelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       .btf_cb-module--btfCb button.btf_cb-module--install {
        background: #39b54a;
        padding: 20px;
        font-size: 30px;
        border-radius: 32px;
        box-shadow: 0 10px 20px -10px #23e332;
        margin-bottom: 25px;
      }
      .wave-module--waveLp button.wave-module--waveBtn {
        animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
        animation-fill-mode: forwards;
      }
      @keyframes slideInFromLeft {
        from {
          background-position: right bottom;
        }
        to {
          background-position: 0 0;
          background-color: #096bfb;
        }
      }
      #disclaimers-module--disclaimers p {
        text-align: center;
        color: #666;
        font-size: 13px;
        width: 466px;
        line-height: 150%;
        margin: 0 auto 12px;
      }
      footer {
        font-family: 'Roboto', sans-serif;
        position: relative;
        width: 100%;
        padding: 32px 0;
      }
      footer ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        list-style: none;
        color: #333;
        font-size: 12px;
        }
        footer ul li {
          padding:0 6px 0 0;
        }

        footer ul li a {
          color:#333;
        }

        footer ul li:last-child {
          padding:0;
        }

        footer ul li:after {
          content: '|';
          padding:0 0 0 6px;
        }

        footer ul li:last-child:after {
          content:none;
          padding:0;
        }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Find Manuals to Read & Print | manualslibrary.co</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}><FfDisclosure language='de-clear'></FfDisclosure></NonbmaWave>
      <Btf_cb_new data={btfData}><FfDisclosure language='de-clear'></FfDisclosure></Btf_cb_new>
      <RawFooter language='de-clear'></RawFooter>
      </section>
    </HomepageLayout>



  )
}
